@import '../../styles/partials/variables.module.scss';

.actionBtn {
  font-weight: 600;
  border-radius: 4px;
  width: fit-content;
  height: 2.5rem;
  font-size: 1rem;
  letter-spacing: 0.01em;
  padding: 0.5rem 1rem;
  text-transform: none;
  &:disabled {
    color: $disabled-gray-2;
    cursor: unset;
  }
  &:focus-visible {
    outline: 2px solid $focused-border;
  }
  &:hover {
    cursor: pointer;
  }
}

.doAction {
  background-color: $primary-blue;
  color: $background-white;
  border: none;
  margin-left: 1rem;
  &:hover {
    background-color: $primary-hover-blue-2;
  }
  &:focus-visible {
    outline: 2px solid #b770fe;
  }
  &:active {
    background-color: $primary-pressed-blue;
  }
  &:disabled {
    background-color: $disabled-gray-1;
  }
}

.cancelAction {
  background-color: white;
  color: $primary-blue;
  border: 1px solid $primary-blue;
  &:disabled {
    border-color: $disabled-gray-2;
  }
  &:hover {
    background-color: $accent-blue;
  }
  &:active {
    background-color: $primary-hover-blue-1;
  }
}

.modalContainer {
  .modalSheet {
    background-color: white;
    border: 1px solid $disabled-gray-1;
    box-shadow: 0px 5px 10px rgba(29, 63, 156, 0.1);
    border-radius: 8px;
    width: 700px;
    height: 430px;

    .modalDialog {
      height: 100%;
      width: 100%;
      padding: 2.5rem 3.5rem;
      display: flex;
      flex-direction: column;
      > h2 {
        margin-bottom: 0.5rem;
        color: #474748;
      }
      > p {
        color: $paragraph-placeholder;
        margin: 0 0 2rem;
      }
      > div.btnContainer {
        width: 100%;
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
      }
    }
  }

  .editModalSheet {
    height: 320px;
  }

  .deleteModalSheet {
    height: 272px;
  }
}

.deleteMemberBtn {
  all: unset;
  display: inline-flex;
  color: $primary-blue;
  cursor: pointer;
}
