@import '../../styles/partials/variables.module.scss';

.outerBox {
  align-items: center;
  cursor: move;
  display: flex;
  padding: 1rem;
  position: absolute;
  z-index: 50;
  &:hover {
    .deleteInput {
      display: unset;
    }
    .inputBox {
      border: 1px solid $neutral-gray;
      border-radius: 0.2rem;
    }
  }
}

.activeColorEditingInputBox {
  border: 1px solid $neutral-gray;
  border-radius: 0.2rem;
}

.inputBox[contenteditable]:empty:focus::before,
.inputBox {
  cursor: text;
  margin: 0;
  width: max-content;
}

.deleteInput {
  all: unset;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  img {
    width: 24px;
    height: 24px;
  }
}
